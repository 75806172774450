<template lang="pug">
Auth(@loginFailed="onFailedAuthentication")
  Header(:content="headerContent")
  div.wrap-confirm-subscription
    ModuleConfirmSubscription(v-if="uid")
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-confirm-subscription {
  margin-top: $header_height;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/shared/Auth'
const {
  mapState: mapStateAuth,
  mapActions: mapActionsAuth
} = createNamespacedHelpers('auth')
import Header from '@/components/shared/Header'
import ModalPage from '@/components/shared/ModalPage.vue'
import ModuleConfirmSubscription from '@/components/module/ModuleConfirmSubscription'

export default {
  components: {
    Auth,
    Header,
    ModalPage,
    ModuleConfirmSubscription
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  data() {
    return {
      headerContent: {
        title: 'プラン更新',
        left: {
          icon: 'mdi-chevron-left',
          action: this.back
        },
      },
    }
  },
  created() {
    //
  },
  methods: {
    onFailedAuthentication() {
      this.$router.push('/sign-in')
    },
    back() {
      this.$router.back()
    }
  }
}
</script>
