import { render, staticRenderFns } from "./ModuleConfirmSubscription.vue?vue&type=template&id=773c97a2&scoped=true&lang=pug&"
import script from "./ModuleConfirmSubscription.vue?vue&type=script&lang=js&"
export * from "./ModuleConfirmSubscription.vue?vue&type=script&lang=js&"
import style0 from "./ModuleConfirmSubscription.vue?vue&type=style&index=0&id=773c97a2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "773c97a2",
  null
  
)

export default component.exports