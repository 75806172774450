<template lang="pug">
  div.wrap-module-confirm-subscription.f.fh
    div(v-if="plan").container-confirm-subscription
      h4.text-center.mb16 チームプランに登録します
      div.f.fc
        div.mb12
          span.block.fz14.mb8 チームプラン（{{plan.pricePerUserPerMonthStr}}円/人/月）
          span.block.fz14 今後、メンバー追加ごとに{{plan.pricePerUserPerMonthStr}}円/人/月が加算されます
      
    ItemButtomBtn(label="次へ" :active="!!plan" @onBtn="next")
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-module-confirm-subscription {
  height: calc(100vh - #{$header_height});
  .container-confirm-subscription {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
  }
}
</style>

<script>
import format from 'date-fns/format'
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
const planSettingsStore = createNamespacedHelpers('planSettings')

import { PLANS } from '@/components/utils/plans'

import ItemButtomBtn from '@/components/item/ItemButtomBtn'
import { sendTrackingEvent } from '../utils/eventTracker'

export default {
  components: {
    ItemButtomBtn
  },
  computed: {
    ...mapStateAuth(['uid']),
    ...planSettingsStore.mapState(['planSettings'])
  },
  data () {
    return {
      plan: null,
      isSaving: false,
    }
  },
  async created () {
    this.updatePlan()
  },
  watch: {
    planSettings() {
      this.updatePlan()
    }
  },
  methods: {
    updatePlan() {
      this.plan = PLANS.find((v) => v.id === this.planSettings.planId)
    },
    async next() {
      // please start create subscription here
      this.$router.push('/team/create')
      sendTrackingEvent(`${this.$route.path}:next`, {}, this.uid)
    }
  }
}
</script>
